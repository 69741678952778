body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2,h1,h3,h4 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New" ,
    monospace;
}

.error { border: 1px solid red; }

.pointer {
    cursor: pointer;
}

.table-condensed thead tr th {
    font-size: 11pt !important;
}

.table-condensed tbody tr td {
    font-size: 11pt !important;
    font-weight: 500;
}

.align-inherit{
    text-align: inherit;
}

.table-condensed tfooter tr td {
    font-size: 11pt !important;
}

.page-bar {
    margin-bottom: 0px !important;
}

/*Default size: 55px */
.select-agroiq {
    height:35px !important;

}

.rdrDay {
    background-color: #03DAC6;
}
.rdrDayDisabled {
    background-color: #E0E0E0 ;
}

.rdrSelected {
    background-color: #018786 !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    border-radius: 0em !important;
}

.rdrStartEdge.rdrEndEdge {
    background-color: #018786 !important;
    color: #018786 !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    border-radius: 0em !important;
}

.rdrStartEdge {
    background-color: #018786 !important;
    color: #018786 !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    border-radius: 0em !important;
}

.rdrEndEdge {
    background-color: #018786 !important;
    color: #018786 !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    border-radius: 0em !important;
}

.rdrDayHovered {
    border-radius: inherit !important;
}

.tableexport-caption {
    display:none !important;
}

/***
Theme Panel
***/
.theme-panel {
  width: 400px;
  margin-top: -15px;
  margin-right: 0px;
  z-index: 100;
  float: right;
  position: relative; }
  .theme-panel > .toggler {
    top: 5px;
    right: 1px;
    height: 40px;
    width: 40px;
    border-radius: 50% !important;
    cursor: pointer;
    position: absolute;
    text-align: center;
    background-color: #fff; }
    .theme-panel > .toggler > i {
      position: relative;
      top: 12px;
      font-size: 20px;
      color: #9fb3ca; }
    .theme-panel > .toggler:hover {
      background: #ACB5C3; }
      .theme-panel > .toggler:hover > i {
        color: #fff; }
  .theme-panel > .toggler-close {
    display: none;
    top: 5px;
    right: 1px;
    z-index: 101;
    cursor: pointer;
    position: absolute; }
    .theme-panel > .toggler-close > i {
      position: relative;
      top: 12px;
      right: 12px;
      font-size: 20px;
      color: #f2f2f2; }
    .theme-panel > .toggler-close:hover {
      opacity: 0.8 ;
      filter: alpha(opacity=80) ; }
  .theme-panel > .theme-options {
    box-shadow: 5px 5px rgba(63, 77, 86, 0.1);
    top: 4px;
    right: 0;
    display: none;
    position: absolute;
    z-index: 100;
    background: #3f4d56;
    border: 1px solid #39454d;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px; }
    .theme-panel > .theme-options > .theme-option {
      color: #eee;
      padding: 11px;
      border-top: 1px solid #46545f;
      margin-top: 0px;
      margin-bottom: 0px; }
      .theme-panel > .theme-options > .theme-option > span {
        text-transform: uppercase;
        display: inline-block;
        width: 115px;
        font-size: 13px;
        font-weight: 300; }
      .theme-panel > .theme-options > .theme-option > select.form-control {
        display: inline;
        width: 100px;
        padding: 2px;
        text-transform: lowercase; }
      .theme-panel > .theme-options > .theme-option.theme-colors {
        border-top: 0; }
        .theme-panel > .theme-options > .theme-option.theme-colors > span {
          display: block;
          width: auto; }
        .theme-panel > .theme-options > .theme-option.theme-colors > ul {
          list-style: none;
          padding: 0;
          display: block;
          margin-bottom: 10px !important;
          margin-top: 15px; }
          .theme-panel > .theme-options > .theme-option.theme-colors > ul > li {
            width: 46px;
            height: 45px;
            margin: 0 4px;
            cursor: pointer;
            list-style: none;
            float: left;
            border: solid 1px #707070;
            /* theme colors */ }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li:first-child {
              margin-left: 0; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li:hover,
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.current {
              border: solid 2px #d64635; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-default {
              background: #2b3643; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-dark {
              background: #333438; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-blue {
              background: #26344B; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-grey {
              background: #4D5B69; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-light {
              background: #f5f5f5; }

@media screen and (max-width:767px) {
    .skin-black .main-header .logo {
        display: none;
    }
}

.skin-black .main-header .logo {
background-color: #fff;
    color: #333;
    border-bottom: 0 solid transparent !important;
    border-right: 0px solid #eee;
    background: white !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.skin-black .main-header .navbar {
    background-color: #fff;
    height: 50px;
}

@media screen and (max-width:767px) {
    .navbar-nav>li>a {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}

.navbar-nav>li>a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

._loading_overlay_wrapper {
    width: 100%;
}

.content-wrapper {
    min-height: 100vh;
}

.greenTd {
    background-color: #26C281;
}

.yellowTd {
    background-color: #E87E04;
}


.redTd {
    background-color: #FF0000;
}

.emptytab {
    background-color: #eee;
}
