.box_header_custom{
    text-transform: uppercase !important;
    background-color: #44b6ae;
}

.title  {
    font-weight: 700 !important;
    color: #FFF;
    vertical-align: middle;
}

.excel-resumen {
    float: right !important;
    border: 0.4px solid black !important;
}

.actions-causas {
    display: inline-block;
    float: right;
}

.actions-item {
    margin-right: 3px;
}
figure {
    background: #fefefe;
    border: 2px solid #fcfcfc;
    box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
    margin: 0 2px 15px;
    padding: 15px;
        padding-bottom: 15px;
    padding-bottom: 10px;
    transition: opacity .4s ease-in-out;
    display: inline-block;
    column-break-inside: avoid;
}

figcaption {
    border: 2px solid #fcfcfc;
    box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
    font-size: 1rem;
    color: #444;
    line-height: 1.5;
}

.muestra-title {
    font-weight: 500 !important;
}
.muestra-title-bonificacion{
    font-weight: 500 !important;
    padding-left: 8%!important;
    text-align: left;
}
.main-row {
    border: 2px solid #d6d6d6;
    background-color: #dfdfdf87;
}

td, th {
    text-align: center;
}

.page-bar {
    padding: 0px;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.page-bar .page-breadcrumb {
    display: inline-block;
    float: left;
    padding: 10px 6px;
    margin: 0;
    list-style: none;
}

.labores-title {
    color: #888;
    font-size: 13px;
    text-shadow: none;
}

.labores-icon {
    display: inline-block;
    color: #888 !important;
    padding: 10px;
}

.labores-title-container {
    display: inline-block;
}

.icon-right {
    color: #888 !important;
    margin-left: 5px !important;
}

.nav-tabs-custom {
    margin-bottom: 2px;
}
.nav-tabs-custom>.nav-tabs>li>a, .nav-tabs-custom>.nav-tabs>li>a:hover {
}

.tab-title {
}
.nav-tabs-custom>.nav-tabs>li>a, .nav-tabs-custom>.nav-tabs>li>a {
    color: white;
}

.nav-tabs-custom>.nav-tabs>li>a:hover {
    color: #44b6ae;
    background-color: white;
}

.calidad-periodal-body {
    background-color: white;
}

.select-anio {
  margin: 2px;
  height: 36px;
  width: 10%;
  float: right;
}

@media only screen and (max-width: 900px) {
  .select-anio {
    margin: 2px;
    height: 36px;
    width: 100%;
    float: right;
  }

  .causas-periodal-select {
      width: 100%;
      margin-right: 0px !important;
      margin-top: 10px;
  }

  .comparacion-finca-causa-select {
      width:100%;
  }

    .causas-periodal-select-labor {
        width: 100%;
    }

    .actions-causas {
        width: 100%;
    }

    .actions-item {
        width: 100%;
    }
}

#cantidad_plantas {
    padding:10px;
}
