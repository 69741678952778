.box_header_custom{
    text-transform: uppercase !important;
    background-color: #44b6ae;
}

.title  {
    font-weight: 700 !important;
    color: #FFF;
    vertical-align: middle;
}

.excel-resumen {
    float: right !important;
    border: 0.4px solid black !important;
}

.actions-causas {
    display: inline-block;
    float: right;
}

.actions-item {
    margin-right: 3px;
}
figure {
    background: #fefefe;
    border: 2px solid #fcfcfc;
    box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
    margin: 0 2px 15px;
    padding: 15px;
        padding-bottom: 15px;
    padding-bottom: 10px;
    transition: opacity .4s ease-in-out;
    display: inline-block;
    column-break-inside: avoid;
}

figcaption {
    border: 2px solid #fcfcfc;
    box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
    font-size: 1rem;
    color: #444;
    line-height: 1.5;
}

.muestra-title {
    font-weight: 500 !important;
}
.muestra-title-bonificacion{
    font-weight: 500 !important;
    padding-left: 8%!important;
    text-align: left;
}
.main-row {
    border: 2px solid #d6d6d6;
    background-color: #dfdfdf87;
}

td, th {
    text-align: center;
}

.page-bar {
    padding: 0px;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.page-bar .page-breadcrumb {
    display: inline-block;
    float: left;
    padding: 10px 6px;
    margin: 0;
    list-style: none;
}

.labores-title {
    color: #888;
    font-size: 13px;
    text-shadow: none;
}

.labores-icon {
    display: inline-block;
    color: #888 !important;
    padding: 10px;
}

.labores-title-container {
    display: inline-block;
}

.icon-right {
    color: #888 !important;
    margin-left: 5px !important;
}

.nav-tabs-custom {
    margin-bottom: 2px;
}
.nav-tabs-custom>.nav-tabs>li>a, .nav-tabs-custom>.nav-tabs>li>a:hover {
}

.tab-title {
}
.nav-tabs-custom>.nav-tabs>li>a, .nav-tabs-custom>.nav-tabs>li>a {
    color: white;
}

.nav-tabs-custom>.nav-tabs>li>a:hover {
    color: #44b6ae;
    background-color: white;
}

.calidad-periodal-body {
    background-color: white;
}

.select-anio {
  margin: 2px;
  height: 36px;
  width: 10%;
  float: right;
}

@media only screen and (max-width: 900px) {
  .select-anio {
    margin: 2px;
    height: 36px;
    width: 100%;
    float: right;
  }

  .causas-periodal-select {
      width: 100%;
      margin-right: 0px !important;
      margin-top: 10px;
  }

  .comparacion-finca-causa-select {
      width:100%;
  }

    .causas-periodal-select-labor {
        width: 100%;
    }

    .actions-causas {
        width: 100%;
    }

    .actions-item {
        width: 100%;
    }
}

#cantidad_plantas {
    padding:10px;
}

@media (min-width: 768px)
.center-wrap {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    overflow: hidden;
    /* position: absolute; */
}

@charset "UTF-8";
/* Cubic Bezier Transition */
/*--------------------------------------------------
    [PLACEHOLDER]
----------------------------------------------------*/
/*--------------------------------------------------
    [TRANSITION]
----------------------------------------------------*/
/* Cubic Bezier Transition */
/*--------------------------------------------------
    [Dropdown Menu v2]
----------------------------------------------------*/
.dropdown-menu-v2 {
  margin-right: 10px !important; }

/*--------------------------------------------------
	[Header]
----------------------------------------------------*/
.page-header {
  margin: 0;
  padding: 0;
  /*background: #2f373e;*/
  border-bottom-color: #384047;
  /* Navbar */
  /* Navbar Brand */
  /* Topbar Actions */ }
  .page-header .navbar {
    z-index: 0;
    border: none;
    position: static;
    margin-bottom: 0;
    border-radius: 0;
    /* Container Fluid */ }
    .page-header .navbar .container-fluid {
      padding-right: 0; }
  .page-header .navbar-brand {
    height: 60px;
    max-height: 60px;
    line-height: 27px;
    border-right: 1px solid #384047; }
    .page-header .navbar-brand img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      display: inline-block;
      vertical-align: middle; }
  .page-header .topbar-actions {
    float: right;
    /* Search box */
    /* Notification */
    /* User Activity */ }
    .page-header .topbar-actions .search-form {
      float: left;
      position: relative;
      display: inline-block;
      background: #2f373e; }
      .page-header .topbar-actions .search-form .input-group {
        display: inherit; }
        .page-header .topbar-actions .search-form .input-group .form-control {
          box-shadow: none;
          border-radius: 0;
          position: relative;
          padding-right: 40px;
          border-top: none;
          border-bottom: none;
          color: #4e5962;
          background: #2f373e;
          width: 250px;
          height: 60px;
          font-size: 15px;
          border-left-color: #384047;
          border-right-color: #384047; }
          .page-header .topbar-actions .search-form .input-group .form-control:-moz-placeholder {
            color: #4e5962; }
          .page-header .topbar-actions .search-form .input-group .form-control::-moz-placeholder {
            color: #4e5962; }
          .page-header .topbar-actions .search-form .input-group .form-control:-ms-input-placeholder {
            color: #4e5962; }
          .page-header .topbar-actions .search-form .input-group .form-control::-webkit-input-placeholder {
            color: #4e5962; }
        .page-header .topbar-actions .search-form .input-group .input-group-btn {
          width: 0;
          height: 60px; }
          .page-header .topbar-actions .search-form .input-group .input-group-btn .btn.submit {
            z-index: 2;
            color: #4e5962;
            margin-left: -44px; }
            .page-header .topbar-actions .search-form .input-group .input-group-btn .btn.submit > i {
              font-size: 16px; }
    .page-header .topbar-actions .btn-group-notification .btn {
      background: none;
      width: 60px;
      height: 60px;
      box-shadow: none !important;
      position: static !important; }
      .page-header .topbar-actions .btn-group-notification .btn .badge {
        padding: 0;
        height: auto;
        font-size: 20px !important;
        min-width: auto;
        font-weight: 500;
        line-height: 1.4;
        background: none;
        text-align: center;
        color: #009dc7;
        box-shadow: none !important; }
    .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 {
      padding: 0;
      min-width: 300px; }
      .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2:before {
        border-color: transparent transparent #21282e transparent; }
      .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .external {
        padding: 15px;
        display: block;
        overflow: hidden;
        background: #21282e;
        letter-spacing: 0.5px; }
        .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .external h3 {
          margin: 0;
          z-index: 1;
          padding: 0;
          float: left;
          color: #bbb;
          font-size: 13px;
          line-height: 1.4;
          max-width: 200px;
          position: relative; }
        .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .external a {
          padding: 0;
          clear: none;
          font-size: 13px;
          font-weight: 300;
          text-align: right;
          color: #009dc7;
          text-transform: lowercase; }
      .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li {
        display: block;
        padding: 15px;
        border-top: 1px solid #2e353c; }
        .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li:before,
        .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li:after {
          display: table;
          content: " "; }
        .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li:after {
          clear: both; }
        .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li:first-child {
          border-top: none; }
        .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li:hover {
          background: #21282e; }
        .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li > a {
          color: #606e7a;
          overflow: hidden; }
          .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li > a .details {
            float: left;
            max-width: 215px; }
            .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li > a .details .label {
              float: left;
              padding: 5px;
              margin-right: 10px; }
          .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li > a .time {
            float: right;
            font-size: 12px;
            padding: 4px 8px;
            background: #2f373e; }
          .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 .dropdown-menu-list > li > a:hover {
            text-decoration: none; }
    .page-header .topbar-actions .btn-group-img .btn {
      padding: 0;
      border-radius: 0;
      line-height: normal;
      background: #333c43;
      height: 60px;
      border-left: 1px solid #384047; }
      .page-header .topbar-actions .btn-group-img .btn > span {
        padding: 9px;
        line-height: 1.4;
        color: #009dc7;
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase; }
      .page-header .topbar-actions .btn-group-img .btn > img {
        text-align: center;
        vertical-align: middle;
        height: 60px; }

/* Page Header Fixed */
.page-header-fixed .page-content {
  margin-top: 80px; }

/* Stylesheet design for under max-width: 479px */
@media (max-width: 479px) {
  /* 479px */
  .page-header {
    /* Navbar */
    /* Navbar Brand */
    /* Topbar Actions */ }
    .page-header .navbar:before {
      top: 59px;
      height: 2px;
      width: 100%;
      content: " ";
      border-width: 1px;
      position: absolute;
      border-color: #384047;
      border-top-style: solid; }
    .page-header .navbar-brand {
      float: none;
      display: block; }
      .page-header .navbar-brand img {
        margin: 0 auto;
        display: block;
        text-align: center; }
    .page-header .topbar-actions {
      /* Search box */
      /* Notification */ }
      .page-header .topbar-actions .search-form {
        z-index: 9;
        margin-left: 0;
        background: #2f373e;
        width: 60px; }
        .page-header .topbar-actions .search-form.open {
          right: 0;
          left: 10px;
          display: block;
          position: absolute;
          width: 100% !important;
          margin-left: -10px !important; }
          .page-header .topbar-actions .search-form.open .input-group {
            width: 100%;
            display: table; }
            .page-header .topbar-actions .search-form.open .input-group .form-control {
              border-right: none;
              z-index: 9 !important;
              width: 100% !important; }
            .page-header .topbar-actions .search-form.open .input-group .btn.submit {
              display: table-cell; }
        .page-header .topbar-actions .search-form .form-control {
          width: 0 !important;
          padding-right: 44px !important; }
        .page-header .topbar-actions .search-form .input-group-btn .btn.submit {
          margin-left: -49px !important; }
      .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 {
        right: -85px; }
        .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2:before {
          right: 100px; } }

/* Stylesheet design between 480px to 767px */
@media (min-width: 480px) and (max-width: 767px) {
  .page-header .topbar-actions {
    /* Search box */ }
    .page-header .topbar-actions .search-form .input-group .form-control {
      width: 175px; } }

/* Stylesheet design for max-width: 767px */
@media (max-width: 767px) {
  .page-header {
    /* Navbar */ }
    .page-header .navbar {
      /* Container Fluid */
      /* Navbar Brand */ }
      .page-header .navbar .container-fluid {
        padding-right: 15px; }
      .page-header .navbar .navbar-brand {
        border-right: none; } }

/* Stylesheet design for min-width: 768px */
@media (min-width: 768px) {
  /* 768px */
  .page-header {
    /* Search box */ }
    .page-header .search-form .form-control {
      transition-duration: 300ms;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1); }
    .page-header .search-form.open .input-group .form-control {
      width: 330px;
      transition-duration: 300ms;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1); } }

/* Page header fixed for above 992px */
@media (max-width: 992px) {
  /* 992px */
  /* Page Header Fixed */
  .page-header-fixed .navbar-fixed-top {
    position: static; }
  .page-header-fixed .page-content {
    margin-top: 20px; } }

/*--------------------------------------------------
	[PAGE CONTENT]
----------------------------------------------------*/
/* Body */
body {
  /*background: #2f373e url(../img/bg_01.jpg) no-repeat;*/
  background: rgba(255, 255, 255, 0.28) !important;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed; }

html {
  height: 100%; }

/* Go2top */
.go2top i,
.go2top i:focus {
  right: 20px;
  bottom: 25px;
  width: 38px;
  height: 38px;
  z-index: 999;
  line-height: 1;
  font-size: 35px;
  position: fixed;
  color: #384047; }

/* Go2top under Page boxed */
.page-boxed .go2top i {
  right: 51px; }

/* Page Boxed */
@media (min-width: 1300px) {
  .page-boxed .container {
    width: 1270px; } }

/*--------------------------------------------------
    [Center Aligned Content]
----------------------------------------------------*/
/* Set displays an element as in inline-level flex container */
@media (min-width: 768px) {
  /* 768px */
  .center-wrap {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    overflow: hidden;
    /*position: absolute; */
  }
  .center-align {
    width: 100%;
    height: 100%;
    display: table; }
  .center-body {
    text-align: center;
    display: table-cell;
    vertical-align: middle; } }

/*--------------------------------------------------
    [Webapp Button]
----------------------------------------------------*/
@media (max-width: 991px) {
  /* 991px */
  .sm-margin-bottom-30 {
    margin-bottom: 30px; } }

.webapp-btn {
  width: 100%;
  display: block;
  text-align: left;
  padding: 11px 15px;
  border: 1px solid #384047; }
  .webapp-btn h3 {
    margin: 0;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.4;
    color: #7C8284;
    /*color: #009dc7;*/
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif; }
  .webapp-btn p {
    color: #586978;
    font-size: 17px;
    margin-bottom: 0;
    text-transform: capitalize; }
  .webapp-btn:focus,
  .webapp-btn:hover {
    text-decoration: none;
    background: rgba(197, 200, 202, 0.72);
    /*background: #009dc7;*/
    border-color: #009dc7;
    transition-duration: 300ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1); 
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.33);}
    .webapp-btn:focus h3,
    .webapp-btn:focus p,
    .webapp-btn:hover h3,
    .webapp-btn:hover p {
      color: #fff; }

/* Media Queries */
@media (max-width: 992px) {
  /* 992px */
  .webapp-btn h3 {
    font-size: 30px; } }

/*--------------------------------------------------
    [Page Content Popup]
----------------------------------------------------*/
.page-content.page-content-popup {
  background: #fff; }

/* Set space for content */
@media (max-width: 480px) {
  /* 480px */
  .page-content.page-content-popup {
    padding-bottom: 20px; } }

@media (max-width: 767px) {
  /* 767px */
  .page-content.page-content-popup {
    margin-top: 20px; } }

/*--------------------------------------------------
    [Page Content]
----------------------------------------------------*/
/* Page Content */
.page-content {
  margin: 20px 0;
  overflow: hidden; }
  .page-content .page-content-fixed-header {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #efefef;
    /* Page Breadcrumb */
    /* Content Header Menu */ }
    .page-content .page-content-fixed-header:before,
    .page-content .page-content-fixed-header:after {
      display: table;
      content: " "; }
    .page-content .page-content-fixed-header:after {
      clear: both; }
    .page-content .page-content-fixed-header .page-breadcrumb {
      float: left;
      list-style: none;
      margin-bottom: 0;
      padding-left: 20px; }
      .page-content .page-content-fixed-header .page-breadcrumb > li {
        margin: 0;
        color: #6c7984;
        font-size: 18px;
        padding: 16px 0 10px 0;
        font-weight: 200;
        display: inline-block; }
        .page-content .page-content-fixed-header .page-breadcrumb > li + li:before {
          position: relative;
          top: -5px;
          font-size: 6px;
          padding: 0 7px 0 5px;
          color: #a6b2bd;
          content: "\F111";
          font-family: FontAwesome; }
        .page-content .page-content-fixed-header .page-breadcrumb > li > a:hover {
          text-decoration: none; }
        .page-content .page-content-fixed-header .page-breadcrumb > li .page-breadcrumb-link {
          color: #101214; }
    .page-content .page-content-fixed-header .content-header-menu {
      float: right;
      /* Menu Toggler */
      /* Dropdown Ajax Menu */ }
      .page-content .page-content-fixed-header .content-header-menu .menu-toggler {
        float: left;
        display: none;
        border: none;
        padding: 20px 15px;
        background: #fff;
        border-left: 1px solid #efefef; }
        .page-content .page-content-fixed-header .content-header-menu .menu-toggler:focus {
          outline: 0; }
        .page-content .page-content-fixed-header .content-header-menu .menu-toggler:hover {
          cursor: pointer; }
          .page-content .page-content-fixed-header .content-header-menu .menu-toggler:hover .icon-bar {
            background: #009dc7; }
        .page-content .page-content-fixed-header .content-header-menu .menu-toggler .icon-bar {
          width: 21px;
          height: 2px;
          display: block;
          background: #a6b2bd; }
          .page-content .page-content-fixed-header .content-header-menu .menu-toggler .icon-bar + .icon-bar {
            margin-top: 5px; }
      .page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu {
        margin: 0;
        padding: 0;
        display: inline;
        position: relative;
        vertical-align: inherit; }
        .page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .btn {
          border-radius: 0;
          background: #fff;
          padding: 16px 30px 17px;
          border-left: 1px solid #efefef; }
          .page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .btn:hover i {
            color: #009dc7; }
          .page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .btn i {
            height: 8px;
            font-size: 5px;
            line-height: 2;
            display: block;
            color: #a6b2bd; }
        .page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .dropdown-menu-v2 {
          margin-top: 70px; }

/* Material Design */
.page-md .content-header-menu .dropdown-ajax-menu .btn {
  box-shadow: none;
  border-left: 1px solid #efefef !important; }

/* Stylesheet design for max-width: 991px */
@media (max-width: 991px) {
  /* 991px */
  /* Page content */
  .page-content .page-content-fixed-header {
    /* Content Header Menu */ }
    .page-content .page-content-fixed-header .page-breadcrumb > li {
      font-size: 18px; }
      .page-content .page-content-fixed-header .page-breadcrumb > li + li:before {
        top: -3px; }
    .page-content .page-content-fixed-header .content-header-menu .dropdown-ajax-menu .btn {
      padding: 13px 21px; }
    .page-content .page-content-fixed-header .content-header-menu .menu-toggler {
      display: block;
      padding: 17px 13px; }
      .page-content .page-content-fixed-header .content-header-menu .menu-toggler .icon-bar {
        width: 20px; } }

/* Stylesheet design for under max-width: 479px */
@media (max-width: 479px) {
  /* 479px */
  /* Page Content */
  .page-content .page-content-fixed-header .page-breadcrumb > li {
    font-size: 16px; } }

/*--------------------------------------------------
    [Page Fixed  Main Content]
----------------------------------------------------*/
.page-content .page-fixed-main-content {
  padding: 20px; }

/* Stylesheet design for min-width: 992px */
@media (min-width: 992px) {
  /* 992px */
  /* Page fixed main content */
  .page-content .page-fixed-main-content {
    overflow: hidden;
    margin-left: 255px; } }

/***
Page sidebar
***/
/* IE8 fix */
.ie8 .page-sidebar {
  width: 235px;
  float: left;
  position: relative;
  margin-right: -100%; }

/* Page Sidebar */
.page-sidebar,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
  border-radius: 4px;
  background: #fff;
  /* Default sidebar menu */ }
  .page-sidebar.navbar-collapse,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover.navbar-collapse {
    padding: 0;
    box-shadow: none; }
    .page-md .page-sidebar.navbar-collapse,
    .page-md .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover.navbar-collapse {
      box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.03); }
  .page-sidebar .page-sidebar-menu,
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
    border: 1px solid #e7ecf1;
    border-radius: 4px;
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    /* 1st level links */
    /* all links */ }
    .page-sidebar .page-sidebar-menu > li,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li {
      display: block;
      margin: 0;
      padding: 0;
      border: 0px; }
      .page-sidebar .page-sidebar-menu > li.heading,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading {
        height: 50px;
        padding: 25px 15px 10px 15px; }
        .page-sidebar .page-sidebar-menu > li.heading > h3,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading > h3 {
          font-size: 13px;
          font-weight: 400;
          padding: 0;
          margin: 0;
          font-weight: 600; }
      .page-sidebar .page-sidebar-menu > li > a,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
        color: #2f373e;
        display: block;
        position: relative;
        margin: 1px 0 0 0;
        border: 0px;
        padding: 12px 15px;
        text-decoration: none; }
        .page-sidebar .page-sidebar-menu > li > a:hover,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a:hover {
          background: #f4f6f7; }
          .page-sidebar .page-sidebar-menu > li > a:hover > i,
          .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a:hover > i {
            color: #94e8ff; }
        .page-sidebar .page-sidebar-menu > li > a > .title,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .title {
          font-size: 14px;
          font-weight: 400; }
        .page-sidebar .page-sidebar-menu > li > a > i,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i {
          color: #94e8ff;
          font-size: 18px;
          text-shadow: none;
          font-weight: 300; }
        .page-sidebar .page-sidebar-menu > li > a > i.glyphicon,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i.glyphicon {
          top: 3px;
          margin-left: 1px;
          margin-right: 4px; }
        .page-sidebar .page-sidebar-menu > li > a > [class^="icon-"],
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > [class^="icon-"] {
          top: 2px;
          margin-left: 1px;
          margin-right: 4px; }
        .page-sidebar-fixed .page-sidebar .page-sidebar-menu > li > a,
        .page-sidebar-fixed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
          transition: all 0.2s ease; }
        .page-sidebar-reversed.page-sidebar-fixed .page-sidebar .page-sidebar-menu > li > a,
        .page-sidebar-reversed.page-sidebar-fixed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a {
          transition: none; }
      .page-sidebar .page-sidebar-menu > li.open > a,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a {
        background: #f4f6f7;
        font-size: 14px; }
        .page-sidebar .page-sidebar-menu > li.open > a > .arrow:before,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow:before {
          color: #94e8ff; }
        .page-sidebar .page-sidebar-menu > li.open > a > i,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > i {
          color: #94e8ff; }
      .page-sidebar .page-sidebar-menu > li.open:hover a,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open:hover a {
        color: inherit; }
        .page-sidebar .page-sidebar-menu > li.open:hover a > .arrow:before,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open:hover a > .arrow:before {
          color: #94e8ff; }
      .page-sidebar .page-sidebar-menu > li.open:hover i,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open:hover i {
        color: #94e8ff; }
      .page-sidebar .page-sidebar-menu > li.active > a,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a {
        border: none;
        text-shadow: none;
        background: #f4f6f7;
        font-size: 14px; }
        .page-sidebar .page-sidebar-menu > li.active > a > i,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > i {
          color: #94e8ff; }
      .page-sidebar .page-sidebar-menu > li.active:hover a,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active:hover a {
        color: inherit; }
        .page-sidebar .page-sidebar-menu > li.active:hover a > .arrow:before,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active:hover a > .arrow:before {
          color: #94e8ff; }
      .page-sidebar .page-sidebar-menu > li.active:hover i,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active:hover i {
        color: #94e8ff; }
    .page-sidebar .page-sidebar-menu li > a > .arrow:before,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before {
      width: 10px;
      float: right;
      margin-right: 5px;
      margin-left: 5px;
      display: inline;
      color: #9ba6b0;
      font-size: 16px;
      font-family: FontAwesome;
      height: auto;
      content: "\F104";
      font-weight: 300;
      text-shadow: none; }
    .page-sidebar .page-sidebar-menu li > a > .arrow.open:before,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before {
      content: "\F107";
      margin-right: 6px; }
    .page-sidebar .page-sidebar-menu li > a:hover > .arrow:before,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a:hover > .arrow:before {
      color: #94e8ff; }
    .page-sidebar .page-sidebar-menu li > a > .badge,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .badge {
      float: right;
      margin-top: 1px;
      margin-right: 0px; }
    .page-sidebar .page-sidebar-menu .sub-menu,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu {
      list-style: none;
      display: none;
      padding: 0;
      margin: 8px 0px 8px 0px; }
      .page-sidebar .page-sidebar-menu .sub-menu li,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li {
        background: none;
        margin: 0px;
        padding: 0px;
        margin-top: 1px !important;
        /* 2nd level sub menu */ }
        .page-sidebar .page-sidebar-menu .sub-menu li:hover > a,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li:hover > a {
          background: #f4f6f7; }
          .page-sidebar .page-sidebar-menu .sub-menu li:hover > a > i,
          .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li:hover > a > i {
            color: #94e8ff; }
        .page-sidebar .page-sidebar-menu .sub-menu li > a,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a {
          color: #2f373e;
          display: block;
          margin: 0;
          padding: 9px 14px 9px 30px;
          text-decoration: none;
          font-size: 14px;
          font-weight: 400;
          background: none; }
          .page-sidebar .page-sidebar-menu .sub-menu li > a > i,
          .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a > i {
            color: #94e8ff;
            font-size: 16px;
            margin-right: 1px; }
          .page-sidebar .page-sidebar-menu .sub-menu li > a .arrow:before,
          .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a .arrow:before {
            margin-top: -4px; }
          .page-sidebar .page-sidebar-menu .sub-menu li > a .arrow.open:before,
          .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > a .arrow.open:before {
            margin-top: -2px; }
        .page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu,
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu {
          margin: 0; }
          .page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li,
          .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li {
            /* 3rd level sub menu */ }
            .page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > a,
            .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > a {
              padding-left: 40px; }
            .page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu,
            .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu {
              margin: 0; }
              .page-sidebar .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu > li > a,
              .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li > .sub-menu > li > .sub-menu > li > a {
                padding-left: 50px; }
      .page-sidebar .page-sidebar-menu .sub-menu.always-open,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu.always-open {
        display: block; }
    .page-sidebar .page-sidebar-menu li.active > a,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a {
      background: #f4f6f7; }
      .page-sidebar .page-sidebar-menu li.active > a > i,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > i {
        color: #94e8ff; }
    .page-sidebar .page-sidebar-menu li.active > .sub-menu,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > .sub-menu {
      display: block; }

@media (min-width: 992px) {
  /* 992px */
  .page-sidebar {
    width: 235px;
    float: left;
    position: relative;
    margin: 20px -100% 20px 20px; }
    .page-full-width .page-sidebar {
      display: none !important; }
    .page-sidebar.navbar-collapse {
      max-height: none !important; }
    .page-sidebar .page-sidebar-menu {
      padding: 10px 0; }
  .page-sidebar-reversed .page-sidebar {
    float: right;
    margin-right: 0;
    margin-left: -100%; }
  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    margin-left: -235px; }
  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar-wrapper {
    position: relative;
    float: right; }
  .page-sidebar-fixed .page-sidebar {
    position: fixed !important;
    margin-left: 0;
    top: 95px; }
  .page-sidebar-fixed .page-sidebar-menu > li.last {
    margin-bottom: 15px !important; }
  .page-sidebar-fixed .page-sidebar-menu .sub-menu {
    height: auto !important; }
  /* Sidebar Closed */
  .page-sidebar-closed .page-sidebar {
    width: 54px !important; }
    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
      /* sidebar */
      width: 54px !important; }
      .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li {
        /* hide opened sub menu */ }
        .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.heading > h3 {
          display: none; }
        .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li.open > .sub-menu,
        .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > .sub-menu {
          display: none !important; }
        .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover {
          width: 289px !important;
          position: relative !important;
          z-index: 10000;
          display: block !important; }
          .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover > a {
            border-radius: 0 4px 0 0;
            text-align: left; }
            .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover > a > i {
              display: inline-block;
              margin-right: 5px; }
            .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover > a > .title {
              display: inline !important;
              padding-left: 20px; }
            .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover > a > .badge {
              display: block !important; }
            .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover > a > .selected {
              display: none; }
          .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover > .sub-menu {
            width: 234px;
            position: absolute;
            z-index: 2000;
            left: 55px;
            margin-top: 0;
            top: 100%;
            display: block !important;
            border-radius: 0 0 4px 4px; }
            .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover > .sub-menu > li > a {
              padding-left: 15px !important; }
            .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover > .sub-menu > li > .sub-menu > li > a {
              padding-left: 30px !important; }
            .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:not(.heading):hover > .sub-menu > li > .sub-menu > li > .sub-menu > li > a {
              padding-left: 45px !important; }
        .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a {
          min-height: 46px; }
          .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .badge,
          .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .title,
          .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a > .arrow {
            display: none !important; }
  .page-sidebar-closed.page-sidebar-reversed .page-sidebar {
    margin-left: -54px;
    width: 54px; }
    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
      /* sidebar */ }
      .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > .sub-menu {
        left: auto;
        margin-left: -55px; }
      .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
        margin-left: -235px; }
        .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a {
          border-radius: 4px 0 0 0; }
          .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > .title {
            padding-left: 0;
            padding-right: 15px; }
          .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li:hover > a > i {
            margin-right: 0;
            margin-left: 2px; }
    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed > li > a {
      padding-right: 7px;
      padding-left: 11px; }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
    width: 235px !important;
    display: block;
    z-index: 10000; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
      width: 235px !important; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .selected {
        display: none !important; }
  .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover {
    width: 235px !important;
    display: block;
    z-index: 10000;
    margin-left: -235px !important; }
    .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover .page-sidebar-menu {
      width: 235px !important; }
  .page-sidebar-closed.page-sidebar-hide .page-sidebar {
    display: none !important; }
  /* Default Sidebar Menu With Hoverable Submenu */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu {
    display: none;
    width: 234px;
    z-index: 2000;
    position: absolute;
    border-radius: 4px; }
    .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu > li > a {
      margin: 3px; }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li.active .sub-menu,
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li.open .sub-menu {
    display: none !important; }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li a > .arrow {
    display: none; }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow {
    display: block;
    float: right;
    position: absolute;
    right: 0;
    margin-top: -20px;
    background: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 12px double transparent;
    border-bottom: 12px double transparent;
    border-left: 0; }
    .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow:after,
    .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow:before {
      display: none; }
    .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > a > .arrow {
      right: auto;
      left: 0;
      border-right: 0; }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    display: inline-block !important; }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .arrow {
    z-index: 1;
    right: -1px;
    margin-top: -20px; }
    .page-fontawesome .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .arrow {
      margin-top: -34px; }
    .page-glyphicons .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > a > .arrow {
      margin-top: -38px; }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    margin-left: 236px;
    margin-top: -50px; }
    .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
      margin-left: -234px !important; }
    .page-sidebar-closed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
      margin-left: 0; }
    .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu {
      margin-left: 234px;
      margin-top: -44px !important; }
      .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu {
        margin-left: -234px !important; }
      .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu > li .sub-menu > li > a {
        padding-left: 10px;
        padding-right: 10px; } }

@media (max-width: 991px) {
  /* 991px */
  .page-sidebar {
    border-top: 0 !important;
    margin: 20px; }
    .page-sidebar .selected {
      display: none !important; }
    .page-sidebar.navbar-collapse {
      max-height: none;
      /* set some max height to have a scrollable menu on mobile devices */ }
      .page-sidebar.navbar-collapse.collapse {
        display: none !important; }
      .page-sidebar.navbar-collapse.in {
        border-top: 0 !important;
        margin: 20px;
        position: relative;
        overflow: hidden !important;
        overflow-y: auto !important;
        display: block !important; }
      .page-sidebar.navbar-collapse.navbar-no-scroll {
        max-height: none !important; }
    .page-sidebar .mega-menu-responsive-content {
      padding: 10px 18px 10px 45px; }
  .page-full-width .page-sidebar-menu {
    display: block; } }

@media (min-width: 768px) and (max-width: 991px) {
  /* 768px & 991px */
  .page-sidebar .btn-navbar.collapsed .arrow {
    display: none; }
  .page-sidebar .btn-navbar .arrow {
    position: absolute;
    right: 25px;
    width: 0;
    height: 0;
    top: 50px;
    border-bottom: 15px solid #5f646b;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent; } }

@media (max-width: 767px) {
  /* 767px */
  /* Page sidebar */
  .page-sidebar,
  .page-sidebar.in {
    margin: 10px 10px 10px 10px !important; }
    .page-header-fixed.page-header-fixed-mobile .page-sidebar,
    .page-header-fixed.page-header-fixed-mobile .page-sidebar.in {
      margin-top: 10px !important; } }

/*--------------------------------------------------
    [Copyright]
----------------------------------------------------*/
.copyright {
  color: #55616b;
  margin: 20px 0 10px;
  text-align: center; }
  .copyright a {
    z-index: 1;
    position: relative; }

@media (min-width: 480px) {
  /* 480px */
  .copyright {
    left: 0;
    right: 0;
    bottom: 5px;
    position: absolute; } }

/*--------------------------------------------------
    [Copyright v2]
----------------------------------------------------*/
.copyright-v2 {
  color: #55616b;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 0;
  background: #fff; }
  .copyright-v2 a {
    z-index: 1; }

/******************
Page Quick Sidebar
******************/
/***
Quick Sidebar Layout
***/
/* Quick Sidebar Notification */
.quick-sidebar-notification {
  position: relative; }
  .quick-sidebar-notification .badge {
    top: -40px;
    left: -5px;
    position: absolute; }

/* Quick sidebar toggler */
.quick-sidebar-toggler {
  top: 50%;
  right: 0;
  padding: 0;
  z-index: 99;
  border: none;
  outline: none;
  position: fixed;
  margin-top: -30px;
  background: #009dc7;
  width: 45px;
  height: 45px; }
  .quick-sidebar-toggler i {
    padding: 5px;
    font-size: 20px;
    color: #fff; }
    .quick-sidebar-toggler i:before {
      content: "\E065"; }
    .page-quick-sidebar-open .quick-sidebar-toggler i:before {
      content: "\E066"; }

/* Page Portlet Fullscreen */
.page-portlet-fullscreen .page-quick-sidebar-wrapper,
.page-portlet-fullscreen .page-quick-sidebar-toggler {
  z-index: -1; }

/* Quick sidebar toggler */
.page-quick-sidebar-toggler {
  overflow: hidden;
  z-index: 99999;
  display: none;
  width: 28px;
  height: 27px;
  position: fixed;
  top: 10px;
  right: 15px;
  text-align: center;
  padding-top: 6px; }
  .page-quick-sidebar-toggler:hover {
    background: #303a43; }
  .page-quick-sidebar-open .page-quick-sidebar-toggler {
    display: inline-block; }
    .page-quick-sidebar-open .page-quick-sidebar-toggler:hover {
      background: none; }
  .page-quick-sidebar-toggler > i {
    color: #99a8b5;
    font-size: 17px; }
    .page-quick-sidebar-toggler > i:hover {
      color: #fff !important; }
    .page-quick-sidebar-open .page-quick-sidebar-toggler > i:before {
      content: "\E066"; }

/* Quick sidebar wrapper */
.page-quick-sidebar-wrapper {
  transition: right 0.3s;
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 320px;
  right: -320px;
  overflow: hidden;
  color: #99a8b5;
  background: #21282e; }
  .page-quick-sidebar-open .page-quick-sidebar-wrapper {
    transition: right 0.3s;
    right: 0; }
  .page-quick-sidebar-wrapper .page-quick-sidebar {
    background: #21282e;
    /* Quick sidebar tabs content */
    /* Quick sidebar general list heading */
    /* Quick sidebar general list-items */
    /* Inner content */
    /* Quick sidebar list */
    /* Quick sidebar list item */
    /* Quick sidebar list item shown */
    /* Quick sidebar chat */
    /* Quick sidebar alerts */
    /* Quick sidebar settings */ }
    .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs {
      margin: 0;
      padding: 0;
      border: 0; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li {
        display: table-cell !important;
        width: 1%  !important;
        padding: 0;
        margin: 0;
        float: none; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li > a {
          position: relative;
          display: block;
          text-align: center;
          border: 0;
          height: auto;
          font-size: 14px;
          padding: 45px 15px 8px;
          text-transform: uppercase;
          background: none;
          margin-right: 0;
          color: #90a1af;
          border: 0;
          border-bottom: 3px solid rgba(243, 86, 93, 0.3);
          border-radius: 0;
          outline: none !important; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li > a > .badge {
            position: absolute;
            top: 45px;
            right: 3px; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li.active > a,
        .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li:hover > a {
          border: 0;
          border-bottom: 3px solid #f3565d;
          background: none;
          color: #fff;
          text-decoration: none; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu {
          border: 0;
          background: #36424c;
          box-shadow: 5px 5px rgba(97, 117, 135, 0.1);
          margin-top: 8px;
          margin-right: 20px; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu:before {
            position: absolute;
            top: -7px;
            right: 19px;
            display: inline-block !important;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            border-bottom: 7px solid #36424c;
            content: ''; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu:after {
            position: absolute;
            top: -6px;
            right: 20px;
            display: inline-block !important;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            border-bottom: 7px solid #36424c;
            content: ''; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li > a {
            padding: 10px 15px;
            color: #99a8b5; }
            .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li > a > i {
              color: #93a3b1; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li:hover > a {
            background: #3d4a55;
            color: #99a8b5; }
            .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li:hover > a > i {
              color: #9babb8; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li.active > a {
            background: #38444f;
            color: #99a8b5; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li.divider {
            background-color: #3d4a55; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li.open > a.dropdown-toggle {
          border-bottom: 3px solid #f3565d;
          background: none;
          text-decoration: none;
          color: #90a1af; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .tab-content {
      margin: 0;
      padding: 0; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .list-heading {
      font-size: 16px;
      margin: 10px 10px;
      color: #6c8296; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .list-items {
      margin: 0;
      padding: 0;
      list-style: none; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li {
        margin: 0;
        padding: 15px;
        background: none;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #273037; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li:hover {
          background: #273037; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li:last-child {
          border-bottom: 0; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .list-items.borderless li {
        border: 0; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .inner-content {
      margin: 10px 10px; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-list {
      position: absolute !important;
      width: 320px !important;
      transition: margin 0.3s; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item {
      width: 320px;
      position: absolute !important;
      width: 320px !important;
      transition: margin 0.3s;
      margin-left: 320px; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav {
        padding: 15px 10px 0px 10px; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list {
          vertical-align: middle;
          display: inline-block;
          font-size: 14px;
          color: #90a1af; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list:hover {
            text-decoration: none; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list > i {
            font-size: 17px;
            line-height: 17px;
            vertical-align: top;
            margin-right: 3px; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list {
      transition: margin 0.3s;
      margin-left: -320px; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list .slimScrollBar,
      .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list .slimScrollRail {
        display: none !important; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-item {
      transition: margin 0.3s;
      margin-left: 0; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users {
      padding: 10px 0;
      position: relative; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media {
        padding: 15px 15px; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object {
          border-radius: 50% !important;
          width: 45.71429px;
          opacity: 0.8;
          filter: alpha(opacity=80);
          float: left;
          margin-right: 10px; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:before,
          .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after {
            content: " ";
            display: table; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after {
            clear: both; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover {
          cursor: pointer; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover .media-object {
            opacity: 1;
            filter: alpha(opacity=100); }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading {
          margin: 5px 0 0 0;
          font-size: 14px; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-sub {
          font-size: 11px;
          text-transform: uppercase;
          color: #657b8d; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-small {
          font-size: 10px;
          color: #5d7081; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-status {
          margin-top: 10px;
          right: 10px;
          position: absolute;
          display: inline-block; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages {
      padding: 0px 10px;
      position: relative; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post {
        transition: display 0.3s;
        padding: 5px 0;
        margin: 10px auto;
        font-size: 13px; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .body {
          color: #c3c3c3;
          display: block; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .avatar {
          width: 45.71429px;
          border-radius: 50% !important; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .avatar {
          float: left;
          margin-right: 10px; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .avatar {
          float: right;
          margin-left: 10px; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .name {
          font-size: 12px;
          font-weight: 300;
          color: #8496a7; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .datetime {
          font-size: 12px;
          font-weight: 300;
          color: #8496a7; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .message {
          display: block;
          padding: 5px;
          position: relative;
          color: #90a1af;
          background: #36424c; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message {
          text-align: left;
          margin-left: 55px; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message .arrow {
            display: block;
            position: absolute;
            top: 9px;
            left: -6px;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right-width: 6px;
            border-right-style: solid;
            border-right-color: #36424c; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message {
          margin-right: 55px;
          text-align: right; }
          .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message .arrow {
            display: block;
            position: absolute;
            top: 9px;
            right: -6px;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left-width: 6px;
            border-left-style: solid;
            border-left-color: #36424c; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .name,
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .datetime {
          text-align: right; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-form {
      padding: 20px 10px 15px 10px; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list {
      padding: 10px 0;
      position: relative; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a {
        color: #7e91a2; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .label {
          margin-top: 5px; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .desc {
          text-decoration: underline;
          padding: 0;
          color: #788c9e; }
        .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .date {
          color: #5d7081; }
    .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list {
      padding: 10px 0;
      position: relative; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .bootstrap-switch {
        margin-top: -3px;
        float: right;
        border: 0;
        min-width: 59px; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .form-control {
        width: 75px !important;
        padding: 4px 4px !important;
        float: right;
        border: 0;
        margin-top: -4px; }
      .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li select.form-control {
        padding: 4px 0px !important; }

    .webapp-btn-disabled {
            width: 100%;
            display: block;
            cursor: auto !important;
            text-align: left;
            padding: 11px 15px;
            border: 1px solid #384047; 
            text-decoration: none !important;
        }
        .webapp-btn-disabled h3 {
            margin: 0;
            font-size: 42px;
            font-weight: 500;
            line-height: 1.4;
            color: #7C8284;
            /*color: #009dc7;*/
            text-transform: uppercase;
            font-family: 'Oswald', sans-serif; 
        }
        .webapp-btn-disabled p {
            color: #586978;
            font-size: 17px;
            margin-bottom: 0;
            text-transform: capitalize; 
        }

        .webapp-btn-disabled img {
            filter: url('#grayscale'); /* Versión SVG para IE10, Chrome 17, FF3.5, Safari 5.2 and Opera 11.6 */
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            filter: grayscale(100%); /* Para cuando es estándar funcione en todos */
            -webkit-filter: Gray();
                    filter: Gray(); /* IE4-8 and 9 */
            transition: all 0.5s ease;
            }
            .grises img:hover { 
            -webkit-filter: grayscale(0%);
            -moz-filter: grayscale(0%);
            -ms-filter: grayscale(0%);
            -o-filter: grayscale(0%);
            filter: none;
            transition: all 0.5s ease;
        }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2,h1,h3,h4 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New" ,
    monospace;
}

.error { border: 1px solid red; }

.pointer {
    cursor: pointer;
}

.table-condensed thead tr th {
    font-size: 11pt !important;
}

.table-condensed tbody tr td {
    font-size: 11pt !important;
    font-weight: 500;
}

.align-inherit{
    text-align: inherit;
}

.table-condensed tfooter tr td {
    font-size: 11pt !important;
}

.page-bar {
    margin-bottom: 0px !important;
}

/*Default size: 55px */
.select-agroiq {
    height:35px !important;

}

.rdrDay {
    background-color: #03DAC6;
}
.rdrDayDisabled {
    background-color: #E0E0E0 ;
}

.rdrSelected {
    background-color: #018786 !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    border-radius: 0em !important;
}

.rdrStartEdge.rdrEndEdge {
    background-color: #018786 !important;
    color: #018786 !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    border-radius: 0em !important;
}

.rdrStartEdge {
    background-color: #018786 !important;
    color: #018786 !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    border-radius: 0em !important;
}

.rdrEndEdge {
    background-color: #018786 !important;
    color: #018786 !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    border-radius: 0em !important;
}

.rdrDayHovered {
    border-radius: inherit !important;
}

.tableexport-caption {
    display:none !important;
}

/***
Theme Panel
***/
.theme-panel {
  width: 400px;
  margin-top: -15px;
  margin-right: 0px;
  z-index: 100;
  float: right;
  position: relative; }
  .theme-panel > .toggler {
    top: 5px;
    right: 1px;
    height: 40px;
    width: 40px;
    border-radius: 50% !important;
    cursor: pointer;
    position: absolute;
    text-align: center;
    background-color: #fff; }
    .theme-panel > .toggler > i {
      position: relative;
      top: 12px;
      font-size: 20px;
      color: #9fb3ca; }
    .theme-panel > .toggler:hover {
      background: #ACB5C3; }
      .theme-panel > .toggler:hover > i {
        color: #fff; }
  .theme-panel > .toggler-close {
    display: none;
    top: 5px;
    right: 1px;
    z-index: 101;
    cursor: pointer;
    position: absolute; }
    .theme-panel > .toggler-close > i {
      position: relative;
      top: 12px;
      right: 12px;
      font-size: 20px;
      color: #f2f2f2; }
    .theme-panel > .toggler-close:hover {
      opacity: 0.8 ;
      filter: alpha(opacity=80) ; }
  .theme-panel > .theme-options {
    box-shadow: 5px 5px rgba(63, 77, 86, 0.1);
    top: 4px;
    right: 0;
    display: none;
    position: absolute;
    z-index: 100;
    background: #3f4d56;
    border: 1px solid #39454d;
    border-radius: 4px; }
    .theme-panel > .theme-options > .theme-option {
      color: #eee;
      padding: 11px;
      border-top: 1px solid #46545f;
      margin-top: 0px;
      margin-bottom: 0px; }
      .theme-panel > .theme-options > .theme-option > span {
        text-transform: uppercase;
        display: inline-block;
        width: 115px;
        font-size: 13px;
        font-weight: 300; }
      .theme-panel > .theme-options > .theme-option > select.form-control {
        display: inline;
        width: 100px;
        padding: 2px;
        text-transform: lowercase; }
      .theme-panel > .theme-options > .theme-option.theme-colors {
        border-top: 0; }
        .theme-panel > .theme-options > .theme-option.theme-colors > span {
          display: block;
          width: auto; }
        .theme-panel > .theme-options > .theme-option.theme-colors > ul {
          list-style: none;
          padding: 0;
          display: block;
          margin-bottom: 10px !important;
          margin-top: 15px; }
          .theme-panel > .theme-options > .theme-option.theme-colors > ul > li {
            width: 46px;
            height: 45px;
            margin: 0 4px;
            cursor: pointer;
            list-style: none;
            float: left;
            border: solid 1px #707070;
            /* theme colors */ }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li:first-child {
              margin-left: 0; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li:hover,
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.current {
              border: solid 2px #d64635; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-default {
              background: #2b3643; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-dark {
              background: #333438; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-blue {
              background: #26344B; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-grey {
              background: #4D5B69; }
            .theme-panel > .theme-options > .theme-option.theme-colors > ul > li.color-light {
              background: #f5f5f5; }

@media screen and (max-width:767px) {
    .skin-black .main-header .logo {
        display: none;
    }
}

.skin-black .main-header .logo {
background-color: #fff;
    color: #333;
    border-bottom: 0 solid transparent !important;
    border-right: 0px solid #eee;
    background: white !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.skin-black .main-header .navbar {
    background-color: #fff;
    height: 50px;
}

@media screen and (max-width:767px) {
    .navbar-nav>li>a {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}

.navbar-nav>li>a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

._loading_overlay_wrapper {
    width: 100%;
}

.content-wrapper {
    min-height: 100vh;
}

.greenTd {
    background-color: #26C281;
}

.yellowTd {
    background-color: #E87E04;
}


.redTd {
    background-color: #FF0000;
}

.emptytab {
    background-color: #eee;
}

